.drop-zone-wrapper{
  border: 1px #ced4da solid;
  height: 150px;
  border-radius: 5px;
  width: 100%;
  background: #f7f7f7;
  margin-bottom: 50px;

  div{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
    padding: 10px;
    i{
      margin: auto auto 0 auto;
      font-size: 60px;
    }
    p{
      margin: 10px auto auto auto;
      font-size: 18px;
    }
  }


}
