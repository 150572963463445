.dashboard-main-wrapper{
  width: 100%;
  height: 100%;


  .navbar-wrapper{
    min-height: 56px !important;
    border-bottom: 1px solid #dfdfdf;
    .navbar{
      min-height: 56px !important;
    }
    .company-dropdown{
      width: 100%;
    }
  }

  .dashboard-viewer{
    height: 100%;
    background: white;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }


  .form-dropdown{
    .error__control{
      border: 1px solid #dc3545;
    }

    .success__control{
      border: 1px solid #28a745;
    }
  }

  .datetime-picker-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    .ant-picker{
      width: 100%;
    }



  }

  .time-picker-wrapper{
    .time-picker{
      width: 100%;
      height: 38px;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: .25rem;
    }


  }

}

.ant-picker-time-panel{

  .ant-picker-content{
    .ant-picker-time-panel-column:first-child{
      overflow-y: scroll;
    }
  }


  .ant-picker-time-panel-column{
    &:after{
      content: none;
    }

    /* width */
    &::-webkit-scrollbar {
      width: 2px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.toolbar-dashboard{
  background: #f7f7f7;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;

  .divider{
    width: 1px;
    background: #dfdfdf;
    margin: 0 10px 0 5px;
  }

  .float-right{
    margin: auto 0 auto auto;
  }


  button, a{
    margin-right: 5px;
    &.btn-light{
      border-color: #e6e8ea;
    }
  }
}

.modal-header{
  padding: 10px 15px !important;
  font-family: 'Roboto-Light', serif;

  button{
    margin: -10px -15px !important;
  }
}

.modal-body{
  padding: 15px !important;
}



