.navigation-bar-wrapper{
  box-shadow: 0 1px 3px rgba(60,64,67, 0.3), 0 4px 8px 3px rgba(60,64,67, 0.15);

  .container{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .navbar-brand{
    padding: 0;
  }

  .navBar-links-wrapper{

    a:not(:last-child){
      border-right: 1px solid rgba(255,255,255,.5);
    }

    .nav-link {
      padding: .2rem 1rem;
    }
  }
}
