.window-info-wrapper{
  display: flex;
  flex-direction: row;
  max-width: 400px;

  .window-body{
    padding: 5px;
  }

  a{
    border: 1px solid #b5c534;
    background: #b5c534;
    color: white;
    position: relative;
    z-index: 1;
    font-size: 14px;
    margin: 6px auto 0 20px;
    text-align: center;
    display: block;
    width: 150px;
    height: 30px;
    line-height: 15px;
  }

  img{
    width: 100px;
    height: 100px;
    object-fit: contain;
    margin: 0 10px 0 0;
    padding-right: 10px;
    border-right: 1px solid #dfdfdf;
  }

  .window-body{


    .card-product-name{
      font-size: 14px;
      margin-bottom: 5px;
      i{
        margin-right: 5px;
        color: #000;
        width: 15px;
        text-align: center;
      }
    }

    .card-product-description{
      font-size: 14px;
      i{
        margin-right: 5px;
        color: #000;
        width: 15px;
        text-align: center;
      }
    }

    .online{
      background: #28a745;
      width: 50px;
      text-align: center;
      padding: 5px;
      border-radius: 15px;
      color: white;
      font-size: 12px;
      display: block;
      margin: 10px 20px;
    }

    .blob {
      background: #28a745;
      box-shadow: 0 0 0 0 rgba(40, 167, 69, 1);
      transform: scale(1);
      animation: pulse-black 2s infinite;
    }

    @keyframes pulse-black {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.7);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
      }

    }
  }
}



.map-settings{
  display: flex;
  height: 160px;
  position: absolute;
  width: 100%;

  .directions-btn{
    border-radius: 100%;
    color: #000000;
    cursor: pointer;
    z-index: 1000;
    width: 40px;
    height: 40px;
    background: white;
    display: flex;
    position: absolute;
    top: 10px;
    font-size: 16px;
    box-shadow: rgba(0, 0, 0 , 0.3) 0 1px 4px -1px;
    i{
      margin: auto;
    }
  }
}


.search-box-wrapper{
  border-radius: 5px;
  width: 450px;
  height: 90px;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(136,147,156,.5);
  box-shadow: 0 0 10px rgba(136,147,156,.5);
  max-width: calc(100% - 70px);
  border: 1px solid rgba(0,0,0,.125);
  transition: opacity 0.5s ease-in-out;


  &:before {
    content: "";
    display: inline-block;
    width: 0;
    height: 25px;
    border-left: 2px dotted #b5c534;
    position: absolute;
    left: 24px;
    top: 30px;
    z-index: 0;

  }

  .search-box {
    position: relative;
    padding-left: 22px;
    margin: 15px 20px;
    padding-bottom: 5px;
    .autocomplete-box{
      .form-control{
        border-bottom: 1px solid #dfdfdf !important;
      }
    }

    .form-control{
      height: 30px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #3c3c4c;
      border-radius: 0;
      border: none;
      background: #fff;
      -webkit-box-shadow: none;
      box-shadow: none;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      padding: 6px 0;
    }

    &:after{
      content: "\f3c5";
      font-family: 'Font Awesome 5 Free' !important;
      display: inline-block;
      width: 14px;
      height: 18px;
      position: absolute;
      left: -2px;
      bottom: 13px;
      z-index: 1;
      background-repeat: no-repeat;
      background-size: 100% auto;
      font-weight: 900;
      color: #1e6bc1;
      text-align: center;

    }

    &:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 2px solid #1565c0;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 11px;
      z-index: 1;
      background: #fff;
    }
  }

  .vehicle-box{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    padding: 0;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 0;
    margin-top: 5px;
    overflow: hidden;

    li{
      -webkit-box-flex: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex: 1 1 25%;
      flex: 1 1 25%;
      text-align: center;
      padding: 10px;
      cursor: pointer;
      -webkit-transition-duration: .3s;
      transition-duration: .3s;
      font-size: 16px;

      &.active{
        background: #1c75b7;
        color: white;
      }

      &:hover{
        background: #dfdfdf;
      }
    }
  }
}


