@import "../../styles/global";

.search-bar-widget{
  margin-top: 0;
  margin-bottom: auto;
  position: relative;

  .job-type-wrapper{
    color: white;
    display: flex;
    flex-direction: row;
    margin: 0 -10px 5px -10px;

    .clear-job-filter{
      display: flex;
      height: 30px;
      width: 30px;
      border-radius: 100%;
      cursor: pointer;

      i{
        margin: auto;
      }
    }
  }
}

.search-bar-container{

  border: 8px solid rgba(0,0,0,0.2);
  border-radius: 50px;
  padding: 0 !important;


  .search-bar-wrapper{
    display: flex;
    flex-direction: row;
    width: 100%;
    background: white;
    border-radius: 50px;
    z-index: 1;
    position: relative;
    min-height: 50px;

    button{
      border-radius: 0 50px 50px 0;
      border: 1px solid #b5c534;
      background: #b5c534;
      color: white;
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: flex;
      font-weight: bold;
      span{
        margin: auto;
      }

      &:focus,  &:active{
        box-shadow: none !important;
        outline: transparent !important;
      }
    }

    .city-dropdown{
      height: 100%;
      .city-dropdown__control{
        border-radius: 50px 0 0 50px;
        border: 0;
        border-right: 1px solid hsl(0,0%,80%);
        height: 100%;
        width: 100%;
        box-shadow: 0 0 transparent !important;

        .city-dropdown__value-container{
          padding-left: 15px;

          .city-dropdown__input{
            padding-left: 5px;
          }

          .city-dropdown__placeholder{
            padding-left: 5px;
          }

          .city-dropdown__single-value{
            border-radius: 20px;
            padding: 0 0 0 5px;
            overflow: hidden;

            .city-dropdown__multi-value__label{
              padding: 3px 5px;
            }
          }
        }
      }
    }

    .branch-dropdown{
      height: 100%;
      .branch-dropdown__control{
        border-radius: 0 0 0 0;
        border: 0 !important;
        height: 100%;
        width: 100%;
        box-shadow: 0 0 transparent !important;


        .branch-dropdown__value-container{
          padding-left: 10px;

          .branch-dropdown__multi-value{
            border-radius: 20px;
            padding: 0 0 0 5px;
            overflow: hidden;
            background-color: #1c75b7;

            .branch-dropdown__multi-value__label{
              padding: 3px 5px;
              color: white;
            }

            svg path{
              color: white !important;
            }
          }
        }
      }
    }

  }
}


.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 16px;
  margin-right: 15px;
}
.control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.control_indicator {
  position: absolute;
  top: 5px;
  left: 5px;
  height: 20px;
  width: 20px;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 100%;
}
.control:hover input ~ .control_indicator,
.control input:focus ~ .control_indicator {
  background: #b5c534;
}

.control input:checked ~ .control_indicator {
  background: #b5c534;
  border: 0;
}
.control:hover input:not([disabled]):checked ~ .control_indicator,
.control input:checked:focus ~ .control_indicator {
  background: #b5c534;
}
.control input:disabled ~ .control_indicator {
  background: #c3c3c3;
  opacity: 0.6;
  pointer-events: none;
}
.control_indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.control input:checked ~ .control_indicator:after {
  display: block;
}
.control-radio .control_indicator {
  border-radius: 50%;
}

.control-radio .control_indicator:after {
  left: 7px;
  top: 7px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background: #b5c534;
  transition: background 250ms;
}
.control-radio input:disabled ~ .control_indicator:after {
  background: #7b7b7b;
}.control-radio .control_indicator::before {
   content: '';
   display: block;
   position: absolute;
   left: 0;
   top: 0;
   width: 4.5rem;
   height: 4.5rem;
   margin-left: -1.3rem;
   margin-top: -1.3rem;
   background: #b5c534;
   border-radius: 3rem;
   opacity: 0.6;
   z-index: 99999;
   transform: scale(0);
 }
