.starter-card-wrapper{
  margin: 5px;

  .age{
    font-size: 12px;
    display: block;
  }

  img{
    width: 50px;
    height: 50px;
    object-fit: cover;
    object-position: center;
    border-radius: 100%;
  }
  .showMore{
    span{
      margin-bottom: 5px;
    }
  }

  .start-wrapper{
    font-size: 12px;
    margin-top: 5px;
    i{
      color: #ffc107;
    }
  }

  a{
    color: #b1c122;
    font-weight: 700;
  }
}
