.card-image-gallery-card{

  .card-img-top{
    height: 300px;
    background-color: #343a40;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    direction: rtl;
    position: relative;
    overflow: hidden;
    display: flex;

    .spinner-grow{
      margin: auto;
      z-index: 10;
    }

    img{
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transition: opacity .15s ease-in-out;
      -moz-transition: opacity .15s ease-in-out;
      -ms-transition: opacity .15s ease-in-out;
      -o-transition: opacity .15s ease-in-out;
      transition: opacity .15s ease-in-out;

      &.img-cover{
        object-fit: cover;
      }

      &.img-contain{
        object-fit: contain;
      }
    }

    .btn-wrappers{
      background: rgba(0,0,0,0.3);
      position: absolute;
      height: 50px;
      top: 0;
      left: 0;
      width: 100%;
      display: flex;

      .btn-delete-image{
        border-radius: 100%;
        padding: 0;
        display: flex;
        width: 30px;
        height: 30px;
        z-index: 10;
        margin: auto 10px auto auto;

        i{
          margin: auto;
        }
      }

      .btn-cover, .btn-contain{
        width: 30px;
        height: 30px;
        padding: 0;
        display: flex;
        i{
          margin: auto;
        }
        margin-top: auto;
        margin-bottom: auto;
        z-index: 10;
      }

      .btn-cover{
        margin-right: auto;
        margin-left: 0;
      }

      .btn-contain{
        margin-right: 5px;
        margin-left: 10px;
      }
    }

  }
  .card-body{
   padding: 15px 10px 5px;
  }

  .card-footer{
    padding: 10px;
  }
}
