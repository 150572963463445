@import "../../styles/global";

.search-view-wrapper{
  min-height: 100vh;
  min-width: 100%;
  margin: 0 !important;
  padding: 0 !important;

  .list-view-port{
    min-height: calc(100vh - 56px);
    max-height: calc(100vh - 56px);
    height: calc(100vh - 56px);
    background: #f3f3f3;
  }


}
