.carousel-wrapper{

  background: #f7f6f6;
  padding-bottom: 30px;

  h3, h5{
    text-align: left;
  }

  h3{
    color: #b6c622;
  }

  h5{
    font-family: Roboto-Light, serif;
    margin-bottom: 35px;
  }

  .carousel-button-group{


    .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
      position: absolute !important;
      left: calc(100% - 120px) !important;
      top: -50px !important;
      opacity: 0.4;

      &:hover{
        opacity: 0.8;
      }
      &:focus, &:active {
        box-shadow: none;
        outline: none;
        opacity: 0.8;
      }
    }

    .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right{
      position: absolute;
      right: 20px;
      top: -50px;
      opacity: 0.4;
      &:hover{
        opacity: 0.8;
      }
      &:focus, &:active {
        box-shadow: none;
        outline: none;
        opacity: 1;
      }
    }
  }
}
