.cell-action-wrapper{
  float: right;
  button{
    margin-right: 5px;
  }
}

.standard-cell{
  display: flex;
  padding: 5px;
  span{
    margin: auto auto auto 0;
  }
};


.link-cell{
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  border-radius: 5px;

  &.left{
    text-align: left !important;
  }

  &.warning{
    background: #fff3cd;
    a{
      color: #856404 !important;
    }
  }

  a{
    color: rgba(0, 0, 0, 0.65);
    margin: auto;
    width: 100%;
    display: flex;
    span{
      margin: auto 5px;
      padding-left: 10px;
      width: calc(100% - 41px);
      overflow: hidden;
      text-overflow: ellipsis;
    }
    i{
      margin: auto 10px auto auto;
    }
  }

  a:hover{
    color: #1890ff;
  }
}


.toggle-cell{
  display: flex;
  height: 100%;
  zoom: 1.3;
  form{
    margin: auto;
    .custom-control-input:checked~.custom-control-label::before{
      background: #28a745 !important;
      border: 1px solid #28a745 !important;
    }
  }
}

.rt-thead.-header{
  background: #343a40;
  color: white;
  box-shadow: none !important;
}

.rt-th.rt-resizable-header{
  border-right-color: #676767 !important;
}

.rt-resizable-header-content{
  padding: 10px 5px;
  text-align: left;
}

.table-th-icon{
  text-align: left;
}
