.job-check-hero-wrapper{
  background-color: #1c75b7;
  min-height: 80vh;
  padding-top: 35px;
  background-position-x: 115%; 
  background-size: contain;
  background-repeat: no-repeat;
  color: white;

  h3{
    color: white;
    font-weight: 500;
    
  }

  p{
    color: white;
    font-size: 16px;
    
  }

  .badge-success {
    color: #fff;
    background-color: #b6c622;
  }
}
