.qa-wrapper{
  margin-top: 50px;
  margin-bottom: 50px;

  h3, h5{
    text-align: left;
  }

  h3{
    color: #b6c622;
  }

  h5{
    margin-bottom: 50px;
    font-family: Roboto-Light;
  }


  .card-title{
    display: flex;
    flex-direction: row;
    min-height: 50px;
    margin-bottom: 20px;
    i{
      margin-right: 10px;
      font-size: 35px;
      margin-top: auto;
      margin-bottom: auto;
    }
    div{
      margin: auto auto auto 0;
    }

  }
}
