@import "../../styles/global";

.home-hero-banner-wrapper{
  background-position: center;
  background-size: cover;
  background-color: #1c75b7;
  background-blend-mode: multiply;
  height: 80vh;
  padding: 0 !important;
  display: flex;
  flex-direction: column;

  .hero-header{

    .lineOne{

    }

    color: white;
    width: 100%;
    text-align: center;
    margin: auto 20px 60px auto;
    font-size: 50px;
    display: flex;
    flex-direction: column;

    >div{
      margin: 10px auto;
    }
  }
}
