
.main-login-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 100000000;
  background-color: white;
  background-size: cover;

  .flex {
    display: flex;
  }

  .login-portlet {
    background: white;
    margin: auto;
    width: 100%;
    max-width: 600px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);

    .logo {
      background: #343a40;
      padding: 30px;
      display: flex;
      flex-direction: column;
      border-radius: 5px 5px 0 0;

      img {
        margin: auto;
      }
    }

    .form {
      display: flex;
      min-height: 200px;

      .input-group{
        margin-bottom: 10px;
      }

      .error-message {
        color: red;
        font-size: 12px;
      }

      > div {
        padding: 0 15px;
        margin: auto;
        width: 100%;
        max-width: 500px;
      }

      button {
        background-color: #b5c534 !important;
        border: 0;
        margin-top: 15px;

        i {
          margin-right: 10px;
        }

        &:hover {
          filter: brightness(95%);
        }
      }
    }
  }
}
