.product-list-wrapper{
  padding: 0 !important;
  height: calc(100vh - 56px);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .product-list-search-bar-wrapper{
    background: #8d9194;
    padding: 10px 10px 20px 10px;
  }

  .product-list-results{
    overflow-y: auto;
    padding: 10px;

  }
}
