
.image-company-wrapper{
  //display: flex;
  //flex-direction: row;
  //flex-wrap: wrap;
  display: grid;
  column-gap: 10px;
  margin-bottom: 15px;
  border: 0;

  img{
    height: 150px;
    width: 100%;
    margin-bottom: 5px;
    object-fit: cover;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr 1fr 1fr;
    img{
      height: 100px;
    }
  }

  @media (min-width: 576px) {
    grid-template-columns: 1fr 1fr 1fr;
    img{
      height: 120px;
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    img{
      height: 134px;
    }
  }

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    img{
      height: 150px;
    }
  }

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    img{
      height: 134px;
    }
  }
}
