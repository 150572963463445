.product-detail-wrapper{
  padding: 0 !important;
  margin: 0;

  .profile-wrapper{
    margin-top: 50px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 0 1px rgba(0,0,0,.15), 0 2px 3px rgba(0,0,0,.2);


    .cover-image{

      position: relative;
      width: 100%;
      height: 200px;
      padding: 15px;
      border-bottom: 3px solid #b1c122;
      z-index: 1;

      &.default{
        background-size: 200px 200px;
        background-repeat: repeat;
      }

      &.cover{
        background-position: center;
        background-size: cover;
      }

      .profile-image{
        width: 160px;
        height: 160px;
        position: absolute;
        bottom: -80px;
        img{
          width: 160px;
          height: 160px;
          max-height: 160px;
          object-fit: contain;
        }

        .online{
          background: #28a745;
          width: 50px;
          text-align: center;
          padding: 5px;
          border-radius: 15px;
          color: white;
          position: absolute;
          top: -10px;
          right: -10px;
          z-index: 2;
          font-size: 12px;
          display: block;
        }

        .blob {
          background: #28a745;
          box-shadow: 0 0 0 0 rgba(40, 167, 69, 1);
          transform: scale(1);
          animation: pulse-black 2s infinite;
        }

        @keyframes pulse-black {
          0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.7);
          }

          70% {
            transform: scale(1);
            box-shadow: 0 0 0 10px rgba(40, 167, 69, 0);
          }

        }
      }

    }

    .product-basic-info{
      min-height: 100px;
      padding: 15px 15px 15px 190px;
      background: white;
      position: relative;

      .title{
        font-size: 24px;
        line-height: 1.33333;
      }
    }
  }

  .product-hero{
    margin: 10px;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
    border-bottom: 5px solid #b1c122;

  }

  .product-blog{
    margin-top: 50px;
    margin-bottom: 20px;


    .product-blog-body{

      .career-wrapper{

        padding-top: 15px;
        padding-bottom: 15px;
        background: #ededed;
        border-radius: 5px;
        border: 0;
        margin-bottom: 30px;
        margin-top: 30px;
      }

      section {
        border-bottom: 1px solid #ededed;
        padding: 0 10px 20px 10px;
        margin-bottom: 10px;
        margin-top: 20px;

        .event-jumbotron{
          box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
          padding: 0 !important;
          display: flex;
          border-radius: 5px;
          flex-direction: row;
          overflow: hidden;
          margin-bottom: 20px;
          .jumbo-image{
            width: 250px;
            min-width: 250px;
            height: auto;
            img{
              width: 250px;
              height: 100%;
              object-fit: cover;
            }

          }
          .jumbo-body{
            width: 100%;
            padding: 1.5rem 2rem;
            display: flex;
            flex-direction: column;
            .container{
              padding: 0;

              .btn-success{

                margin-right: 10px;
              }
              .btn-primary{

              }
            }

          }


        }
      }

      .row:last-child .col section{
        border-bottom: 0;
        margin-bottom: 0;
      }

      .row:first-child .col section{
        margin-top: 0;
      }

    }


    .product-blog-sidebar{

      @media (min-width: 992px) {
        border-left: 1px solid #ededed;
      }

      @media (max-width: 992px) {
       margin-bottom: 30px;
      }


      .adviser-card{
        background: white;
        text-align: center;
        border: 1px solid #ededed;
        border-radius: 5px;
        margin-bottom: 5px;
        position: sticky;
        top: 0;
        padding: 10px;

        .card-body{
          padding: 0 !important;
        }

        .avatar-wrapper{
          display: flex;
          padding: 10px;

          span{
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 100%;
            margin: auto;
            display: flex;
            background: #dee2e6;

            i{
              margin: auto;
              font-size: 35px;
              color: #7b7b7b;
            }

          }

          img{
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 100%;
            margin: auto;
          }
        }
      }


      .contact{
        display: flex;
        flex-direction: column;
        p{
          border-top: 1px solid #ededed;
          margin-top: 10px;
          text-align: center;
          padding-top: 10px;
        }

        .btn-detail{
          border: 1px solid #b5c534;
          background: #b5c534;
          color: white;
          position: relative;
          z-index: 1;
          display: flex;
          font-size: 14px;
          margin: auto;
        }
      }



      .detail-map-wrapper{
        height: 350px;
        margin: auto -5px 5px;
      }
    }
  }




  .other-companies-wrapper{
    margin-top: 30px;
    background: #ededed;
  }

  .main-product-container{
    position: absolute;
    top: 0;
    background: #00000069;
    padding-bottom: 15px;
    padding-top: 10px;



    >.row{
      height: 100%;
    }

    .main-product-detail{
      display: flex;
      flex-direction: column;

      .title{
        margin-top: 10px;
        color: white;
      }

      .description{

      }
    }
  }

    .gallery{
      max-height: 500px;
      min-height: 500px;
      .detail-gallery-wrapper{
        height: 100%;

        .carousel-inner{
          height: 100%;

          .carousel-item{
            height: 100%;

            img{
              width: 100%;
              height: 100%;

              &.img-contain{
                object-fit: contain;
                object-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
              }

              &.img-cover{
                object-fit: cover;
                background-size: cover;
              }

            }
          }
        }
      }



    }



    .product-detail-hero-wrapper{
      background: blue;
      padding-top: 100px;
      min-height: 400px;

      .product-detail-hero{

      }

    }
}



.like{
  min-width: 45px;
  width: 45px;
  display: flex;

  &.liked{

    button{
      color: #ff004e;
      i{
        animation: heart-burst .5s ease-in-out;
      }

    }
  }

  @keyframes heart-burst {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 0, 78, 0.7);
    }

    70% {
      transform: scale(1.3);
      box-shadow: 0 0 0 5px rgba(255, 0, 78, 0);
    }

    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(255, 0, 78, 0);
    }
  }

  button{
    margin: 0 0 auto auto;
    border-radius: 100%;
    width: 40px;
    height: 40px;
    padding: 0;
    display: flex;
    background: white;
    border: 1px solid #ededed;
    color: #a5a5a5;

    &:focus, &:active{
      box-shadow: none;
    }

    i{
      margin: auto;
      font-size: 20px;
      border-radius: 100%;
      padding: 5px;
    }
  }
}
