.portlet-wrapper{
  padding: 50px 15px;
  .portlet-header{
    padding-bottom: 10px;
    h4{
      font-family: Roboto-Regular, serif;
    }
  }
}

.image-form-wrapper{
  width: 300px;
  max-width: 300px;
  position: relative;
  img{
    margin-top: 10px;
  }

  .btn-delete-image{
    border-radius: 100%;
    padding: 0;
    display: flex;
    width: 30px;
    height: 30px;
    z-index: 10;
    margin: auto 10px auto auto;
    position: absolute;
    right: 0;
    top: 20px;

    i{
      margin: auto;
    }

  }

}


.popover.popover-form-branch{
  max-width: 500px;

  .popover-body{
    padding: 0 !important;

    .card{
      border: 0;

      .card-body{
        padding: 10px 15px;
      }

      .card-footer{
        button{
          margin-right: 10px;
        }
      }
    }
  }

}

.form-branch-wrapper{

  .form-dropdown__menu{

    .form-dropdown__menu-list{
      display: flex;
      flex-wrap: wrap;

      .form-dropdown__option{
        width: 33.333%;
      }

    }
  }
}

.error-dropdown__control{
  border: 1px solid #dc3546 !important;
}
