.score-wrapper{
  background: #f7f7f7;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 30px;
}

.score-criteria-wrapper{

  border: 1px solid #dee2e6;
  border-radius: 5px;
  margin-bottom: 0px;
  text-align: center;
  background: white;

  >.col{
    padding: 15px 10px;

    >.row:first-child .col{
      margin-bottom: 10px;
    }

  }

  .total-score-wrapper{

    .total-label{
      margin-bottom: 0 !important;
    }

    border-left: 1px solid #dee2e6;
    .score-value{
      text-align: center;
      font-family: Roboto-Bold, serif;
      font-size: 20px;
    }
  }
}
