.card-wrapper-job{
  padding: 5px 5px 15px;

  .cover-card{
    background-size: cover;
    min-height: 100px;
    background-repeat: repeat;
    padding: 10px;
    position: relative;

    &:before{
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.25);
      position: absolute;
      top: 0;
      left: 0;
    }

    img{
      width: 90px;
      height: 90px;
      background: white;
      border-radius: 5px;
      border: 1px solid #dfdfdf;
      object-fit: contain;
      padding: 5px;
      position: relative;
      box-shadow: 0 6px 6px -6px black;
    }

  }

  .card{
    box-shadow: 0 6px 6px -6px black;

    .card-body{
      padding: 0 !important;
      position: relative;
      min-height: 200px;

      .list-group.list-group-flush{
        display: flex;
        flex-direction: column;
        min-height: 200px;


        .list-group-item{
          border: 0;
        }
        .list-group-item:last-child{
          border-top: 1px solid #dfdfdf;
          margin-top: auto;

          button{
            border: 1px solid #b5c534;
            background: #b5c534;
            color: white;
            position: relative;
            z-index: 1;
            display: flex;
            font-size: 14px;
            margin: auto 0 auto auto;



          }
        }
      }

      .card-actions-wrapper{
        position: absolute;
        top: -20px;
        width: 100%;
        background: transparent;
        height: 40px;
        z-index: 1;
        border: 0;
        padding: 0 10px;
      }

      .card-job-name{
        font-size: 18px;
        margin-bottom: 0;
        font-family: 'Roboto-Bold';
        border: 0;
        -webkit-line-clamp: 2;
        position: relative;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        padding-bottom: 0;
      }

/*      .card-job-description{
        -webkit-line-clamp: 3;
        position: relative;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        height: 54px;
        font-size: 12px;
        font-weight: 400;
        padding-top: 5px;
      }*/

      .card-address{
        font-size: 12px;
        padding: 5px 20px;
      }

    }

  }








}



