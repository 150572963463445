.nav-section-wrapper{
  margin-bottom: 30px;
  >.col{
    padding: 0;
  }
}

.nav-tool-bar{
  padding: 10px;
  border: 1px solid #dee2e6;
  border-top-color: transparent;
}

