.feedback-wrapper{
  position: fixed;
  top: 15px;
  right: 30px;
  z-index: 10000;

  .message-toast-item{
    min-width: 300px;

    &.toast-status-success{
      .toast-header{
        background: #28a745!important;
        color: white;
      }
    }

    &.toast-status-error{
      .toast-header{
        background: #dc3545!important;
        color: white;
      }
    }

    &.toast-status-warning{
      .toast-header{
        background: #ffc107!important;
        color: white;
      }
    }

    .toast-header{
      i{
        margin-right: 5px;
      }
    }

    .toast-body{
      img{
        object-fit: contain;
      }
    }
  }

}
