.product-search-card-wrapper{

  width: 100%;
  border-bottom: 0 !important;

  &:hover{
    box-shadow: none !important;
  }

  .gauge-widget {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    position: absolute;
    top: 8px;
    right: 40px;

    svg {
      width: 30px;
      height: 35px;
      margin: auto;
      .circle-border {
        stroke-width: 4;
        stroke: #e1e4ea;
        fill: none;
      }

      .circle-progress {
        stroke: #b5c534;
        fill: none;
        stroke-width: 4;
        stroke-linecap: round;
        animation: progressPopulation 1s ease-out forwards;
        transition: all 1s ease-in-out;
      }
    }

    .percentage {
      width: 100%;
      height: 50px;
      position: absolute;
      top: 10px;
      left: 0;
      color: rgba(0, 0, 0, 0.65);
      font-family: Roboto-Bold;
      text-align: center;
      display: flex;
      flex-direction: column;
      span {
        margin: 0 auto auto auto;
        font-size: 10px;
      }
    }
  }

  .total-score{
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background: #f8dd18;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 2px;
    right: 10px;


    small{
      font-size: 10px;
      text-align: center;
      margin: auto auto 0 auto;
    }

    span{
      margin: 0 auto auto auto;
      font-family: Roboto-Bold;
      font-size: 14px;
    }
  }

  &.card{
    flex-direction: row;
    width: 100%;

    &:hover{
     box-shadow: 0 6px 6px -6px rgba(0,0,0,.2);
    }

    .card-img-thumbnail-top{
      width: 200px;
      height: 200px;
      padding: 10px;
      object-fit: contain;
      border-right: 1px solid #dfdfdf;
      background: white;
      border-bottom: 1px solid #dfdfdf;
      border-radius: 2px;
    }

    .card-body{
      padding: 0;
      display: flex;
      flex-direction: column;

      .info-wrapper{
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #dfdfdf;
        margin-bottom: 5px;

        .company-info{
          width: 100%;
        }

        .like{
          min-width: 45px;
          width: 45px;
          display: flex;

          &.liked{
            button{
              color: #ff004e;
              i{
                animation: heart-burst .5s ease-in-out;
              }

            }
          }

          @keyframes heart-burst {
            0% {
              transform: scale(0.95);
              box-shadow: 0 0 0 0 rgba(255, 0, 78, 0.7);
            }

            70% {
              transform: scale(1.3);
              box-shadow: 0 0 0 5px rgba(255, 0, 78, 0);
            }

            100% {
              transform: scale(1);
              box-shadow: 0 0 0 0 rgba(255, 0, 78, 0);
            }
          }

          button{
            margin: 0 0 auto auto;
            border-radius: 100%;
            width: 40px;
            height: 40px;
            padding: 7px 0 0 0;
            display: flex;
            background: transparent;
            border: none;
            color: #a5a5a5;

            &:focus, &:active{
              box-shadow: none;
            }

            i{
              margin: auto;
              font-size: 24px;
              border-radius: 100%;
              padding: 6px 5px;
            }
          }
        }
      }
    }

    .job-offers-wrapper{
      padding: 0 5px;
      display: flex;
      .list-group-item{
        font-size: 12px;
        padding: 0 10px;
        border: 0;
      }
    }

    .branch-wrapper{
      padding: 0 10px;
      .badge{
        margin-right: 5px;
        padding: 4px 8px;
        i{
          margin-right: 4px;
        }
        span, i{
          text-shadow: 1px 1px 5px #808080;
        }
      }

    }

    .card-product-name{
      padding: 10px 10px 0;
      margin-bottom: 5px;
      font-size: 18px;
    }
    .card-product-description{
      font-size: 12px;
      padding: 0 10px 10px;
    }



    .card-actions-wrapper{
      display: flex;
      flex-direction: row;
      padding: 0 10px;
      margin: auto 0 10px 0;

      .btn-card{
        width: 35px;
        height: 35px;
        border-radius: 100%;
        padding: 0;
        display: flex;
        flex-direction: row;
        margin-right: 5px;
        i{
          margin: auto;
          font-size: 14px;
        }
      }

      .btn-detail{
        border: 1px solid #b5c534;
        background: #b5c534;
        color: white;
        position: relative;
        z-index: 1;
        display: flex;
        font-size: 14px;
        margin: auto 0 auto auto;
      }
    }

  }
}


.event-main-wrapper{
  background: white;
  margin-bottom: 10px;
  margin-left: 200px;
  padding: 0 0 10px 10px;
  border-left: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf;
  border-radius: 0 0 3px 3px;
  overflow: hidden;

  .event-list-wrapper{
    display: flex;
    flex-direction: row;
    overflow-y: auto;

    .event-tile-wrapper{
      display: flex;
      flex-direction: row;
      width: calc(50% - 10px);
      margin-right: 10px;
      padding: 0;
      min-width: 250px;
      min-height: 120px;
      overflow: hidden;
      img{
        width: 80px;
        height: 100%;
        object-fit: cover;
      }
      .event-body{
        width: 100%;
        padding-right: 5px;
        .title{
          padding: 5px 10px;
        }
        .time{
          padding: 5px 10px;
          font-size: 12px;
        }

        .ctas{
          width: 100%;
          display: flex;
          padding: 5px 10px;

          a{
            border-bottom: 1px solid;
            height: 18px;
            margin: auto 0;
          }
          .online{
            margin: auto 0 auto auto;
          }
        }

        .online{
          background: #28a745;
          width: 70px;
          height: 28px;
          text-align: center;
          padding: 5px 10px;
          border-radius: 15px;
          color: white;
          font-size: 12px;
          display: block;
          margin: 0 0 0 10px;
        }

        .blob {
          background: #28a745;
          box-shadow: 0 0 0 0 rgba(40, 167, 69, 1);
          transform: scale(1);
          animation: pulse-black 2s infinite;
        }

        @keyframes pulse-black {
          0% {
            transform: scale(0.95);
            box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.7);
          }

          70% {
            transform: scale(1);
            box-shadow: 0 0 0 5px rgba(40, 167, 69, 0);
          }

        }
      }
    }
  }

}


.event-tile-wrapper{
  border: 1px solid #dfdfdf;
  border-radius: 3px;
  padding: 10px;
  background-position: center;
  background-size: cover;
  min-height: 100px;
}
